import React from "react";

import {  Container } from 'reactstrap';
import { SetUserFlagButton } from "Components/Gallium/Flags";

const FlagPage = () => {
    document.title = "Profile Flags | Gallium";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <SetUserFlagButton flag="BACKUP_EARLY_ACCESS" className="mb-4">Backup Early Access</SetUserFlagButton>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FlagPage;

