import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"

// Hypervisors
import HypervisorList from "pages/Hypervisor/HypervisorList"
import HypervisorDetail from "pages/Hypervisor/HypervisorDetail"
import AddHypervisor from "pages/Hypervisor/AddHypervisor"
import HypervisorConfigureStorage from "pages/Hypervisor/ConfigureStorage";
import HypervisorDestroyStorage from "pages/Hypervisor/DestroyStorage";

// Inventory
import SSHKeys from "pages/Inventory/SSHKeys/List"
import AddSSHKey from "pages/Inventory/SSHKeys/Add"
import ImportSSHKeys from "pages/Inventory/SSHKeys/Import"

// VM
import CreateVM from "pages/VM/Create"
import Register from "pages/Authentication/Register";
import VirtualMachineDetail from "pages/VM/View"
import VirtualMachineEdit from "pages/VM/Edit"
import VirtualMachineConnect from "pages/VM/Connect"
import VirtualMachine from "pages/VM/List";

// Templates
import CreateTemplate from "pages/Inventory/Templates/Create"
import TemplatesList from "pages/Inventory/Templates/List";
import TemplateView from "pages/Inventory/Templates/View";
import UserProfile from "pages/Profile/View";

import UsersList from "pages/Settings/Users/List";
import AddUser from "../pages/Settings/Users/Add";
import ResetPassword from "../pages/Authentication/ResetPassword";
import SetPassword from "../pages/Authentication/SetPassword";
import AcceptInvite from "../pages/Authentication/AcceptInvite";
import ServiceGatewayList from "pages/ServiceGateway/List";
import ServiceGatewayAdd from "pages/ServiceGateway/Add";
import AddAlertProfile from "pages/Settings/Alerts/Add";
import AlertProfilesList from "pages/Settings/Alerts/List";
import EditAlertProfile from "pages/Settings/Alerts/Edit";
import MonitoringIncidentList from "pages/Monitoring/List";
import MonitoringIncidentView from "pages/Monitoring/View";
import HypervisorVolumeDetail from "pages/Hypervisor/HypervisorDetail/Volumes/Detail";
import HypervisorDisableStorageSafety from "pages/Hypervisor/DisableStorageSafety";

import SupportAccess from "pages/Settings/Support";
import ServiceProviderOrgsList from "pages/ServiceProvider/List";
import ServiceProviderAddChildOrg from "pages/ServiceProvider/Add";
import HypervisorImportDisk from "pages/Hypervisor/HypervisorDetail/Transfer";
import FlagPage from "pages/Profile/Flags";
import BackupProfileList from "pages/Backup/List";
import BackupProfileAdd from "pages/Backup/Add";
import BackupProfileDetail from "pages/Backup/Detail";
import BackupSetDetail from "pages/Backup/BackupSetDetail";
import AddBackupSet from "pages/Backup/AddBackupSet";

const authProtectedRoutes = [
    // { path: "/dashboard", component: Dashboard },
  
    { path: "/hypervisors", component: <HypervisorList /> },
    { path: "/hypervisors/add", component: <AddHypervisor />},

    { path: "/hypervisors/:id/storage", component: <HypervisorConfigureStorage />},
    { path: "/hypervisors/:id/destroystorage/:poolSlug", component: <HypervisorDestroyStorage />},
    { path: "/hypervisors/:id/disableStorageSafety", component: <HypervisorDisableStorageSafety />},
    { path: "/hypervisors/:hostSlug/volumes/:volumeSlug", component: <HypervisorVolumeDetail />},
    { path: "/hypervisors/:hostSlug/ftm/", component: <HypervisorImportDisk />},
    { path: "/hypervisors/:id", component: <HypervisorDetail />},

    { path: "/inventory/sshkeys", component: <SSHKeys />},

    { path: "/inventory/sshkeys/add", component: <AddSSHKey />},
    { path: "/inventory/sshkeys/import", component: <ImportSSHKeys />},

    { path: "/inventory/templates/create", component: <CreateTemplate />},
    { path: "/inventory/templates", component: <TemplatesList />},
    { path: "/inventory/templates/:slug", component: <TemplateView />},

    { path: "/vm/create", component: <CreateVM />},
    { path: "/vm/:id/connect", component: <VirtualMachineConnect />},
    { path: "/vm/:id/edit", component: <VirtualMachineEdit />},
    { path: "/vm/:id", component: <VirtualMachineDetail />},
    { path: "/vm", component: <VirtualMachine />},

    { path: "/profile", component: <UserProfile />},
    { path: "/profile/flags", component: <FlagPage />},


    { path: "/settings/users", component: <UsersList />},
    { path: "/settings/users/add", component: <AddUser />},

    { path: "/servicegateway", component: <ServiceGatewayList />},
    { path: "/servicegateway/add", component: <ServiceGatewayAdd />},
    { path: "/settings/alerts", component: <AlertProfilesList />},
    { path: "/settings/alerts/add", component: <AddAlertProfile />},
    { path: "/settings/alerts/:slug", component: <EditAlertProfile />},
    { path: "/settings/support", component: <SupportAccess />},

    { path: "/monitoring", component: <MonitoringIncidentList />},
    { path: "/monitoring/:slug", component: <MonitoringIncidentView />},

    { path: "/serviceprovider/org", component: <ServiceProviderOrgsList />},
    { path: "/serviceprovider/org/create", component: <ServiceProviderAddChildOrg />},

    { path: "/backups", component: <BackupProfileList />},
    { path: "/backups/add", component: <BackupProfileAdd />},
    { path: "/backups/:profileSlug/add", component: <AddBackupSet />},
    { path: "/backups/:profileSlug/:setSlug", component: <BackupSetDetail />},
    { path: "/backups/:profileSlug", component: <BackupProfileDetail />},
    





    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: "/",
        exact: true,
        component: <Navigate to="/hypervisors" />,
    },
    { path: "*", component: <Navigate to="/hypervisors" /> },
]

const publicRoutes = [
    { path: "/logout", component: <Logout /> },
    { path: "/login", component: <Login /> },
    { path: "/register", component: <Register /> },
    { path: "/passwordreset", component: <ResetPassword /> },
    { path: "/resetpw/:token", component: <SetPassword /> },
    { path: "/invite/:token", component: <AcceptInvite /> },




]

export { authProtectedRoutes, publicRoutes }
