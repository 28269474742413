import { BackupProfileResponse, BackupService, BackupSetDetailResponse, GalliumApiErrorResponse, GalliumApiMessageResponse, ListBackupProfilesResponse, NewBackupProfileRequest, NewBackupProfileResponse, NewBackupSetRequest, UpdateBackupProfileRequest } from "generated";
import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

export const useListBackupProfiles = () => {
    const listBackupProfiles = () => BackupService.listBackupProfiles()
    
    const { data, error, isLoading } = useSWR<ListBackupProfilesResponse>(`backup`, listBackupProfiles);

    return { data, error, isLoading };
};

export const useNewBackupProfile = () => {
    const newBackupProfile = (key: string, {arg}:{arg:NewBackupProfileRequest}) => BackupService.newBackupProfile(arg)
    
    const { trigger, error, isMutating } = useSWRMutation<NewBackupProfileResponse, GalliumApiErrorResponse, string, NewBackupProfileRequest>(`backup`, newBackupProfile);

    return { trigger, error, isMutating };
};

export const useUpdateBackupProfile = (profileSlug: string) => {
    const updateBackupProfile = (key: string, {arg}:{arg:UpdateBackupProfileRequest}) => BackupService.updateBackupProfile(profileSlug, arg)
    
    const { trigger, error, isMutating } = useSWRMutation<BackupProfileResponse, GalliumApiErrorResponse, string, UpdateBackupProfileRequest>(`backup/${profileSlug}`, updateBackupProfile);

    return { trigger, error, isMutating };
};

export const useNewBackupSet = (profileSlug: string) => {
    const newBackupSet = (key: string, {arg}:{arg:NewBackupSetRequest}) => BackupService.newBackupSet(profileSlug, arg)
    
    const { trigger, error, isMutating } = useSWRMutation<BackupSetDetailResponse, GalliumApiErrorResponse, string, NewBackupSetRequest>(`backup/${profileSlug}`, newBackupSet);

    return { trigger, error, isMutating };
};

export const useGetBackupSetDetails = (profileSlug: string, setSlug: string) => {
    const getBackupSetDetails = () => BackupService.getBackupSetDetails(profileSlug, setSlug)
    
    const { data, error, isLoading } = useSWR<BackupSetDetailResponse>(`backup/${profileSlug}/${setSlug}`, getBackupSetDetails);

    return { data, error, isLoading };
};

export const useDeleteBackupSet = (profileSlug: string, setSlug: string) => {
    const deleteBackupSet = () => BackupService.deleteBackupSet(profileSlug, setSlug)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiMessageResponse, GalliumApiErrorResponse, string>(`backup/${profileSlug}`, deleteBackupSet);

    return { trigger, error, isMutating };
};

