/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DestroyStorageRequest } from '../models/DestroyStorageRequest';
import type { GalliumApiSuccessResponse } from '../models/GalliumApiSuccessResponse';
import type { ProvisionStorageRequest } from '../models/ProvisionStorageRequest';
import type { StorageInfoResponse } from '../models/StorageInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StorageService {

    /**
     * Get information about a host's storage
     * @param hostSlug
     * @returns StorageInfoResponse OK
     * @throws ApiError
     */
    public static getStorageInfo(
        hostSlug: string,
    ): CancelablePromise<StorageInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hosts/{hostSlug}/storage',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                404: `Host not found`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Destroy a storage pool
     * @param hostSlug
     * @param poolSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static destroyStorage(
        hostSlug: string,
        poolSlug: string,
        requestBody: DestroyStorageRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/{hostSlug}/storage/destroy/{poolSlug}',
            path: {
                'hostSlug': hostSlug,
                'poolSlug': poolSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Host not found`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Disable agent-level safety checks that block re-use of disks or non-recommended configurations
     * The agent performs a number of checks on the supplied storage configuration. For example, it will not permit re-use of a disk that was previously configured in a ZFS pool, it will not permit mismatched disk sizes in a mirror group, and it will not permit the boot device to be used for storage. This command tells the agent to skip those checks where possible. It will apply until the next agent restart.
     * @param hostSlug
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static ignoreStorageSafety(
        hostSlug: string,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/{hostSlug}/storage/ignoreSafety',
            path: {
                'hostSlug': hostSlug,
            },
            errors: {
                404: `Host not found`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

    /**
     * Provision storage on a host
     * @param hostSlug
     * @param requestBody
     * @returns GalliumApiSuccessResponse OK
     * @throws ApiError
     */
    public static provisionStorage(
        hostSlug: string,
        requestBody: ProvisionStorageRequest,
    ): CancelablePromise<GalliumApiSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hosts/{hostSlug}/storage/provision',
            path: {
                'hostSlug': hostSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Host not found`,
                500: `Server error`,
                503: `Service unavailable`,
            },
        });
    }

}
