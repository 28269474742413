import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from 'Components/Common/Breadcrumb';
import { Row, Card, CardBody, Container, CardHeader } from 'reactstrap';
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { BackupProfile, DayOfWeek, GalliumApiErrorResponse, NewBackupProfileRequest, UpdateBackupProfileRequest } from 'generated';
import { toast } from 'react-toastify';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useGetAllHosts } from 'GalliumAPIHooks/Host/HostHooks';
import Loader from 'Components/Gallium/Loader';
import BackupProfileSettings from './Settings';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import { useNewBackupProfile, useUpdateBackupProfile } from 'GalliumAPIHooks/Backup/BackupHooks';


interface BackupProfileUpdateProps {
    backupProfile: BackupProfile;
}

const BackupProfileUpdate: React.FC<BackupProfileUpdateProps> = ({backupProfile}) => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useUpdateBackupProfile(backupProfile.slug)

    const [errorObject, setErrorObject] = useState(undefined)

    const handleupdateBackupProfileFail = (error: GalliumApiErrorResponse) => {
        updateBackupProfileFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleupdateBackupProfileSuccess = () => {
        toast.info("Service Gateway Updated")
    }

    const handleUpdateBackupProfileRequest = (values: UpdateBackupProfileRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleupdateBackupProfileFail(error)
            },
            onSuccess() {
                handleupdateBackupProfileSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }


    const updateBackupProfileFormik = useFormik({
        initialValues: {
            name: backupProfile.name,
            fullBackupDay: backupProfile.fullBackupDay,
            expiryDays: backupProfile.expiryDays,
            backupTime: {
                hour: backupProfile.backupTime.hour,
                minute: backupProfile.backupTime.minute,
                timezoneOffsetMinutes: backupProfile.backupTime.timezoneOffsetMinutes
            }
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
            handleUpdateBackupProfileRequest(values)
        },
    });



    document.title = "Update Backup | Gallium";
    const crumbs = [
        {
            name: t("backupProfiles.title"),
            link: "/backups"
        },
        {
            name: t("backupProfiles.add"),
        }
    ]
    return (    
        <React.Fragment>
            <FormikProvider value={updateBackupProfileFormik}>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">Profile Settings</h4>
                    </CardHeader>
                    <CardBody>
                        <ErrorAlert errorObj={errorObject} />

                        <BackupProfileSettings/>
                        
                        <div className="float-end">
                            <GalliumSubmitButton formik={updateBackupProfileFormik} spinner={isMutating} color="success">
                                Update
                            </GalliumSubmitButton>
                        </div>
                    </CardBody>
                </Card>

            </FormikProvider>
        </React.Fragment>
    );
};

export default BackupProfileUpdate;