import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
    Button,
} from "reactstrap";

import * as Yup from "yup";

import classnames from "classnames";

import { round } from "lodash"

import { useFormik, FormikProvider, Field } from "formik";

import Loader from 'Components/Gallium/Loader';

import ErrorNotice from 'Components/Gallium/ErrorNotice';

import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';

import DiskModal from './DiskModal';

import { DiskDetail, DiskGroupType, GalliumApiErrorResponse, PoolRole, ProvisionStorageRequest } from 'generated';
import { useGetHost } from '../../../GalliumAPIHooks/Host/HostHooks';
import { useGetStorageInfo, useProvisionStorage } from '../../../GalliumAPIHooks/Storage/StorageHooks';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import SingleSelectCard from 'Components/Gallium/Forms/SingleSelectCard';
import { UserFlags } from 'Components/Gallium/Flags';

const HypervisorConfigureStorage = () => {
    const {t} = useTranslation()
    const hypervisorId = useParams().id;

    const navigate = useNavigate()

    const [errorObject, setErrorObject] = useState(undefined)

    const {data: hypervisorDetail, error: getHypervisorDetailError, isLoading: isHypervisorDetailLoading } = useGetHost(hypervisorId)
    const {data: hypervisorStorage, error: getStorageInfoError, isLoading: isStorageInfoLoading } = useGetStorageInfo(hypervisorId)
    const {trigger, isMutating} = useProvisionStorage(hypervisorId)

    // Wizard Setup
    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            const modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }
    
    // Handle Mutation
    const handleConfigureStorageFail = (error) => {
        configureStorageFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleConfigureStorageSuccess = () => {
        navigate(`/hypervisors/${hypervisorDetail.slug}?tab=STORAGEPOOLS`);
    }

    const handleConfigureStorageRequest = (values: ProvisionStorageRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleConfigureStorageFail(error)
            },
            onSuccess() {
                handleConfigureStorageSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    // Formik Instance for Storage Wizard
    const configureStorageFormik = useFormik({

        initialValues: {
            diskIds: [],
            groupType: DiskGroupType.SINGLE_DISK,
            configKey: '',
        },
        validationSchema: Yup.object({
            groupType: Yup.string().required("Please select a group Type"),
            configKey: Yup.string().required("You must enter a confirmation key").oneOf([hypervisorStorage?.configKey], "Confirmation key is incorrect"),
            diskIds: Yup.array().test("disks-validation", "Invalid number of disks", function (value) {
                const { groupType } = this.parent; // get the value of the groupType field
                if (groupType === DiskGroupType.SINGLE_DISK && value.length !== 1) {
                // validate for single disk
                    return false;
                } else if (groupType === DiskGroupType.MIRROR && value.length !== 2) {
                // validate for mirror disk
                    return false;
                }
                return true;
            })
        }),
        onSubmit: (values) => {
            // @ts-ignore
            window.Intercom('trackEvent', 'configure-storage-request');
            // save new key
            handleConfigureStorageRequest(values)
        },
    });

    //Tooltips
    const [confirmationBtntooltipOpen, setConfirmationBtnTooltipOpen] = React.useState(false);

    // Disk Info Modal Settings
    const [selectedDisk, setSelectedDisk] = useState<DiskDetail | undefined>(undefined);  
    const [showDiskModal, setShowDiskModal] = useState<boolean | undefined>(false);
    const handleShowDiskInfo = (disk: DiskDetail) => {
        setSelectedDisk(disk)
        setShowDiskModal(true)
    }

    document.title = t("hypervisor.createStoragePool.pageTitle") + " | Gallium";
    const crumbs = [
        {
            name: t("mainNav.hypervisors"),
            link: "/hypervisors"
        },
        {
            name: hypervisorDetail?.name,
            link: `/hypervisors/${hypervisorDetail?.slug}`
        },
        {
            name: t("hypervisor.createStoragePool.pageTitle"),
        }
    ]
    return (    
        <React.Fragment>
            <DiskModal
                showModal={showDiskModal}
                closeModal={() => {
                    setShowDiskModal(false)
                }}
                disk={selectedDisk}
            />

            <div className="page-content">
                {isStorageInfoLoading || isHypervisorDetailLoading ? (<Loader />) : getStorageInfoError || getHypervisorDetailError ? (<ErrorNotice />) : (
                    <Container fluid>
                        <GalliumBreadcrumb title={t("hypervisor.createStoragePool.pageTitle")} crumbs={crumbs} />
                        <Row>
                            <Col>
                                <FormikProvider value={configureStorageFormik}>
                                    <Card className="tablelist-form">
                                        <CardBody className="checkout-tab">
                                            <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                                <Nav
                                                    className="nav-pills nav-justified custom-nav"
                                                    role="tablist"
                                                >
                                                    <NavItem role="presentation">
                                                        <NavLink href="#"
                                                            className={classnames({ active: activeTab === 1, done: (activeTab <= 4 && activeTab >= 0) }, "p-3 fs-15")}
                                                  
                                                        >
                                                            <i className="ri-server-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                                            {t("hypervisor.createStoragePool.configTabTitle")}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem role="presentation">
                                                        <NavLink href="#"
                                                            className={classnames({ active: activeTab === 2, done: activeTab <= 4 && activeTab > 1 }, "p-3 fs-15")}
                                                  
                                                        >
                                                            <i className="ri-file-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                                            {t("hypervisor.createStoragePool.confirmTableTitle")}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <div>
                                                        <h5 className="mb-1">{t("hypervisor.createStoragePool.storageConfigurationHeader")}{" "}{hypervisorDetail.name}</h5>
                                                        <p className="text-muted mb-4">
                                                            {t("hypervisor.createStoragePool.storageConfigurationText")}
                                                        </p>
                                                    </div>
                                                    <UserFlags flag="BACKUP_EARLY_ACCESS" renderIfExists={true}>
                                                        <div className="mt-4">
                                                            <h5 className="fs-14 mb-3">{t("hypervisor.createStoragePool.StoragePoolRole")}</h5>
                                                            <Row className="g-4">
                                                                <SingleSelectCard
                                                                    id="primaryPoolCard"
                                                                    name="role"
                                                                    value={PoolRole.PRIMARY}
                                                                    labelTitle={t("hypervisor.createStoragePool.PrimaryTitle")}
                                                                    labelText={t("hypervisor.createStoragePool.PrimaryText")}
                                                                />
                                                                <SingleSelectCard
                                                                    id="backupPoolCard"
                                                                    name="role"
                                                                    value={PoolRole.BACKUP}
                                                                    labelTitle={t("hypervisor.createStoragePool.BackupTitle")}
                                                                    labelText={t("hypervisor.createStoragePool.BackupText")}
                                                                />
                                                            </Row>
                                                        </div>
                                                    </UserFlags>
                                                    <div className="mt-4">
                                                        <h5 className="fs-14 mb-3">{t("hypervisor.createStoragePool.StoragePoolLayout")}</h5>

                                                        <Row className="g-4">
                                                            <Col lg={6}>
                                                                <div className="form-check card-radio">
                                                                    <Field
                                                                        id="SINGLE_DISK"
                                                                        name="groupType"
                                                                        value={DiskGroupType.SINGLE_DISK}
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        htmlFor="SINGLE_DISK"
                                                                    >
                                                                        <span className="fs-14 mb-1 text-wrap d-block">
                                                                            {t("hypervisor.createStoragePool.SingleTitle")}
                                                                        </span>
                                                                        <span className="text-muted fw-normal text-wrap d-block">
                                                                            {t("hypervisor.createStoragePool.SingleText")}
                                                                        </span>
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="form-check card-radio">
                                                                    <Field
                                                                        id="MIRROR"
                                                                        name="groupType"
                                                                        value={DiskGroupType.MIRROR}
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        htmlFor="MIRROR"
                                                                    >
                                                                        <span className="fs-14 mb-1 text-wrap d-block">
                                                                            {t("hypervisor.createStoragePool.mirrorTitle")}
                                                                        </span>
                                                                        <span className="text-muted fw-normal text-wrap d-block">
                                                                            {t("hypervisor.createStoragePool.mirrorText")}
                                                                        </span>
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="d-flex align-items-center mb-2">
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-14 mb-0">{t("hypervisor.createStoragePool.selectDisks")}</h5>
                                                            </div>
                                                            <div className="flex-shrink-0">

                                                            </div>
                                                        </div>
                                                        <Row className="gy-3">
                                                            {hypervisorStorage.disks.map((item, key) => (
                                                                <Col lg={4} key={key}>
                                                                    <div className="form-check card-radio">
                                                                        <Field
                                                                            id={item.id}
                                                                            name="diskIds"
                                                                            value={item.id}
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={!item.usable}
                                                                        />
                                                                        <Label
                                                                            className="form-check-label"
                                                                            for={item.id}
                                                                        >
                                                                            <span className="fs-14 mb-1 text-wrap d-block">
                                                                                {round(item.sizeBytes / 1073741824,0)} GB Disk
                                                                            </span>
                                                                            <span className="text-muted fw-normal text-wrap d-block">
                                                                                {item.description}
                                                                            </span>
                                                                        </Label>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap p-2 py-1 bg-light rounded-bottom border mt-n1">
                                                                        <div>
                                                                            <Link
                                                                                to="#"
                                                                                className="d-block text-body p-1 px-2 text-muted"
                                                                                onClick={() => {handleShowDiskInfo(item)}}
                                                                            >
                                                                                <i className="ri-information-line text-muted align-bottom me-1"></i>
                                                                                {t("hypervisor.createStoragePool.showDiskModal")}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                
                                                        </Row>
                                                    </div>

                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        {configureStorageFormik.values.diskIds.length > 0 && configureStorageFormik.errors.diskIds === undefined ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-label right ms-auto nexttab"
                                                                onClick={() => {
                                                                    toggleTab(activeTab + 1);
                                                                }}
                                                            >
                                                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                                {t("hypervisor.createStoragePool.continueToConfirm")}
                                                            </button>
                                                        ) : 
                                                            <React.Fragment>
                                                                <Button
                                                                    type="button"
                                                                    id="ConfirmationBtn"
                                                                    className="btn btn-secondary btn-label right ms-auto nexttab"
                                                                >
                                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                                    {t("hypervisor.createStoragePool.continueToConfirm")}
                                                                </Button>
                                                                {/* TODO Fix this Tooltip
                                                                <Tooltip
                                                                    isOpen={confirmationBtntooltipOpen}
                                                                    placement="left"
                                                                    target="ConfirmationBtn"
                                                                    toggle={() => { setConfirmationBtnTooltipOpen(!confirmationBtntooltipOpen) }}>
                                                                    {
                                                                        Object.entries(configureStorageFormik.errors?.diskIds).flatMap(([field, errorMsg], index) => parseErrors(errorMsg).map((ErrorComponent, i) => <div key={`${index}-${i}`}>{ErrorComponent}</div>))
                                                                    }
                                                                </Tooltip> */}
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </TabPane>

                                                <TabPane tabId={2}>
                                                    <div>
                                                        <h5 className="mb-1">{t("hypervisor.createStoragePool.confirmActionTitle")}</h5>
                                                        <p className="mb-4">
                                                            {t("hypervisor.createStoragePool.confirmActionText")}
                                                            {" "}
                                                            <code>{hypervisorStorage.configKey}</code>
                                                        </p>
                                                    </div>

                                                    <Row className="g-4">
                                                        <Field
                                                            label="Enter the Key to Continue"
                                                            name="configKey"
                                                            id="configKey"
                                                            type="text"
                                                            className="mb-3"
                                                            placeholder=""
                                                            component={GalliumInput}
                                                        />
                                                    </Row>

                                                    <ErrorAlert errorObj={errorObject} />

                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light btn-label previestab"
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1);
                                                            }}
                                                        >
                                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                                            {t("hypervisor.createStoragePool.backToSelection")}
                                                        </button>

                                                        <GalliumSubmitButton className="ms-auto nexttab" formik={configureStorageFormik} color="success" spinner={isMutating}>
                                                            {t("hypervisor.createStoragePool.submit")}
                                                        </GalliumSubmitButton>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </FormikProvider>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </React.Fragment>
    );
};
export default HypervisorConfigureStorage