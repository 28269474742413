import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Label, Card, CardBody, Container, Col } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";
import { GalliumApiErrorResponse, NewBackupSetRequest, SshKeyAddRequest } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { useAddSSHKey } from 'GalliumAPIHooks/SSH/SSHHooks';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useTranslation } from 'react-i18next';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useNewBackupSet } from 'GalliumAPIHooks/Backup/BackupHooks';
import { useGetVirtualMachines } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';

const AddBackupSet = () => {
    const {t} = useTranslation()
    const profileSlug = useParams().profileSlug
    const { trigger, isMutating } = useNewBackupSet(profileSlug)
    const {data: virtualMachines, isLoading, error } = useGetVirtualMachines()
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState(undefined)

    const handleNewBackupSetFail = (error: GalliumApiErrorResponse) => {
        AddBackupSetFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleNewBackupSetSuccess = () => {
        toast.success("Backup Set Added")
        navigate(`/backups/${profileSlug}`);
    }

    const handleNewBackupSetRequest = (values: NewBackupSetRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleNewBackupSetFail(error)
            },
            onSuccess() {
                handleNewBackupSetSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const AddBackupSetFormik = useFormik({
        initialValues: {
            vmSlug: '',
        },
        validationSchema: Yup.object({
            vmSlug: Yup.string().required("Please Select VM"),
        }),
        onSubmit: (values) => {
            handleNewBackupSetRequest(values)
        },
    });


    document.title = "Add Backup Set | Gallium";

    const crumbs = [
        {
            name: t("backupProfiles.title"),
            link: "/backups"
        },
        {
            name: profileSlug || "View",
            link: `/backups/${profileSlug}`
        },
        {
            name: "Add Backup Set",
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title="Add Backup Set" crumbs={crumbs} />
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <FormikProvider value={AddBackupSetFormik}>
                            <Card>
                                <CardBody>
                                    <ErrorAlert errorObj={errorObject} />
                                    <GalliumFormHeader icon="info" title="Settings">
                                        <div className="mt-0 mb-3">
                                            <Label className="form-label" htmlFor="vmSlugField">Virtual Machine</Label>

                                            <Col md={6}>
                                                <Field
                                                    id="vmSlugField"
                                                    name="vmSlug"
                                                    as="select"
                                                    className="form-select mb-3"
                                                >
                                                    <option value="">Please Select Virtual Machine</option>
                                                    {virtualMachines?.vms ? (virtualMachines?.vms.map((item) =>(
                                                        <option value={item.slug} key={item.slug}>{item.name}</option>
                                                    ))):(null)}
                                                </Field>
                                            </Col>
                                        </div>
                                    </GalliumFormHeader>
                                    <GalliumSubmitButton formik={AddBackupSetFormik} spinner={isMutating} color="success" className="mt-2 float-end">
                                        Add
                                    </GalliumSubmitButton>
                                </CardBody>
                            </Card>

                            <Col md={6} className="ps-0">
                                <Link to={`/backups/${profileSlug}`}>
                                    <button className="btn btn-link text-decoration-none btn-label">
                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                        Back to Backup Profile
                                    </button>
                                </Link> 
                            </Col>
                            <Col md={6} className="text-end pe-0">   
                                
                            </Col>

                        </FormikProvider>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddBackupSet;