import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    Button,
    Card,
    Container,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import { useGetBackupSetDetails, useListBackupProfiles } from 'GalliumAPIHooks/Backup/BackupHooks';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import BackupSetListComponent from './BackupSetTaskList';
import BackupSetTaskListComponent from './BackupSetTaskList';

const BackupSetDetail = () => {
    const {t} = useTranslation()
    const profileSlug = useParams().profileSlug
    const setSlug = useParams().setSlug
    const {data, error, isLoading} = useGetBackupSetDetails(profileSlug, setSlug)

    document.title = t("backupProfiles.title") +" | Gallium";
    const crumbs = [
        {
            name: t("backupProfiles.title"),
            link: "/backups"
        },
        {
            name: profileSlug || "View",
            link: `/backups/${profileSlug}`
        },
        {
            name: "View Backup Set",
        }
    ]

    return (
        <div className="page-content">
            <Container fluid>
                {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                    <React.Fragment>
                        <GalliumBreadcrumb title={"Backup Set for " + data?.backupSet?.vmReference?.name || "Unknown"} crumbs={crumbs}>
                            {/* <Link to="/backups/add">
                                <Button color="soft-primary" className="add-btn"><i className="mdi mdi-plus-circle-outline me-1"></i> {t("backupProfiles.add")}</Button>
                            </Link> */}
                        </GalliumBreadcrumb>
                        
                        <BackupSetTaskListComponent backupTasks={data.backupTasks}/>
                    </React.Fragment>
                )}
            </Container>
        </div>
    );
};

export default BackupSetDetail;
                    