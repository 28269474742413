import React from 'react';

import FeatherIcon from 'feather-icons-react';

import {
    Col,
    Label,
    Row,
} from "reactstrap"
import { Field } from 'formik';
import { GalliumFormHeader, GalliumInput } from 'Components/Gallium/GalliumForms';
import { DayOfWeek } from 'generated';

const ServiceGatewaySettings = () => {

    return (
        <React.Fragment>
            <GalliumFormHeader icon="info" title="Key Settings">
                <Col md={4} className="mb-3">
                    <Field
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder="Enter a friendly name"
                        type="text"
                        label="Name"
                        component={GalliumInput}
                    />
                </Col>

            </GalliumFormHeader>
            <GalliumFormHeader icon="info" title="Schedule">
                

                <div className="mt-0 mb-3">
                    <Label className="form-label" htmlFor="hypervisorField">Full Backup Day</Label>

                    <Col md={6}>
                        <Field
                            id="fullBackupDay"
                            name="fullBackupDay"
                            as="select"
                            className="form-select mb-3"
                        >
                            {Object.values(DayOfWeek).map((item) => (
                                <option value={item} key={item}>
                                    {item}
                                </option>
                            ))}
                        </Field>
                    </Col>
                </div>
                <Row>
                    <Col md={4} className="mb-3">
                        <Field
                            name="backupTime[hour]"
                            id="backupTime[hour]"
                            className="form-control"
                            placeholder="00"
                            type="number"
                            label="Hour"
                            component={GalliumInput}
                        />
                    </Col>
                    <Col md={4} className="mb-3">
                        <Field
                            name="backupTime[minute]"
                            id="backupTime[minute]"
                            className="form-control"
                            placeholder="00"
                            type="number"
                            label="Minute"
                            component={GalliumInput}
                        />
                    </Col>
                    <Col md={4} className="mb-3">
                        <Field
                            name="backupTime[timezoneOffsetMinutes]"
                            id="backupTime[timezoneOffsetMinutes]"
                            className="form-control"
                            placeholder="00"
                            type="number"
                            label="Offset"
                            component={GalliumInput}
                        />
                    </Col>
                </Row>
            </GalliumFormHeader>
            <GalliumFormHeader icon="info" title="Retention">
                

                <Col md={4} className="mb-3">
                    <Field
                        name="expiryDays"
                        id="expiryDays"
                        className="form-control"
                        placeholder="00"
                        type="number"
                        label="Retention Days"
                        component={GalliumInput}
                    />
                </Col>

            </GalliumFormHeader>
        </React.Fragment>
    )
};

export default ServiceGatewaySettings;
