import useSWR from "swr";
import useSWRMutation from 'swr/mutation'

import { StorageService } from "../../generated/services/StorageService";
import { StorageInfoResponse } from "../../generated/models/StorageInfoResponse";
import { ProvisionStorageRequest } from "../../generated/models/ProvisionStorageRequest";
import { DestroyStorageRequest, GalliumApiErrorResponse, GalliumApiSuccessResponse } from "generated";

export const useGetStorageInfo = (hostSlug: string) => {
    const getStorageInfo = () => StorageService.getStorageInfo(hostSlug)

    const { data, error, isLoading } = useSWR<StorageInfoResponse>(`hosts/${hostSlug}/storage`, getStorageInfo);

    return { data, error, isLoading };
};

export const useProvisionStorage = (hostSlug: string) => {
    const provisionStorage = (key: null, {arg}:{arg:ProvisionStorageRequest}) => StorageService.provisionStorage(hostSlug, arg)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, ProvisionStorageRequest>(`hosts/${hostSlug}`, provisionStorage);

    return { trigger, error, isMutating };
};

export const useDestroyStorage = (hostSlug: string, poolSlug: string) => {
    const destroyStorage = (key: null, {arg}:{arg:DestroyStorageRequest}) => StorageService.destroyStorage(hostSlug, poolSlug, arg)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, DestroyStorageRequest>(`hosts/${hostSlug}`, destroyStorage);

    return { trigger, error, isMutating };
};

export const useIgnoreStorageSafety = (hostSlug: string) => {
    const ignoreStorageSafety = () => StorageService.ignoreStorageSafety(hostSlug)
    
    const { trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`hosts/${hostSlug}`, ignoreStorageSafety);

    return { trigger, error, isMutating };
};