import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
    Container,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import BackupListComponent from './List';

const BackupProfileList = () => {
    const {t} = useTranslation()
    document.title = t("backupProfiles.title") +" | Gallium";
    const crumbs = [
        {
            name: t("backupProfiles.title"),
            link: "/backups"
        },
        {
            name: "View All",
        }
    ]
    return (
        <div className="page-content">
            <Container fluid>
                <GalliumBreadcrumb title={t("backupProfiles.list")} crumbs={crumbs}>
                    <Link to="/backups/add">
                        <Button color="soft-primary" className="add-btn"><i className="mdi mdi-plus-circle-outline me-1"></i> {t("backupProfiles.add")}</Button>
                    </Link>
                </GalliumBreadcrumb>
                <BackupListComponent />
            </Container>
        </div>
    );
};

export default BackupProfileList;
                    