import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { DayOfWeek, GalliumApiErrorResponse, NewBackupProfileRequest } from 'generated';
import { toast } from 'react-toastify';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useGetAllHosts } from 'GalliumAPIHooks/Host/HostHooks';
import Loader from 'Components/Gallium/Loader';
import BackupProfileSettings from './Settings';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import { useNewBackupProfile } from 'GalliumAPIHooks/Backup/BackupHooks';


const BackupProfileAdd = () => {
    const {t} = useTranslation()
    const {data: hypervisors, isLoading, error } = useGetAllHosts()
    const { trigger, isMutating } = useNewBackupProfile()
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState(undefined)

    const handleCreateBackupProfileFail = (error: GalliumApiErrorResponse) => {
        createBackupProfileFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleCreateBackupProfileSuccess = () => {
        toast.info("Service Gateway Submitted")
        navigate("/backups");
    }

    const handleCreateBackupProfileRequest = (values: NewBackupProfileRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleCreateBackupProfileFail(error)
            },
            onSuccess() {
                handleCreateBackupProfileSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }


    const createBackupProfileFormik = useFormik({
        initialValues: {
            targetHostSlug: '',
            name: '',
            fullBackupDay: DayOfWeek.SUNDAY,
            backupTime: {
                hour: 20,
                minute: 0,
                timezoneOffsetMinutes: 0
            },
            expiryDays: 180
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
            handleCreateBackupProfileRequest(values)
        },
    });



    document.title = "Add Service Gateway | Gallium";
    const crumbs = [
        {
            name: t("backupProfiles.title"),
            link: "/backups"
        },
        {
            name: t("backupProfiles.add"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("backupProfiles.add")} crumbs={crumbs} />
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <FormikProvider value={createBackupProfileFormik}>
                            <Card>
                                <CardBody>
                                    <ErrorAlert errorObj={errorObject} />

                                    <BackupProfileSettings hypervisors={hypervisors}/>
                                    
                                    <div className="float-end">
                                        <GalliumSubmitButton formik={createBackupProfileFormik} spinner={isMutating} color="success">
                                            Create
                                        </GalliumSubmitButton>
                                    </div>
                                </CardBody>
                            </Card>

                        </FormikProvider>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BackupProfileAdd;