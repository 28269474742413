import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";

import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import Loader from 'Components/Gallium/Loader';
import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import { useDestroyStorage, useGetStorageInfo } from 'GalliumAPIHooks/Storage/StorageHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { DestroyStorageRequest, GalliumApiErrorResponse } from 'generated';
import { useTranslation } from 'react-i18next';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';


const HypervisorDestroyStorage = () => {
    const {t} = useTranslation()
    const hypervisorId = useParams().hostSlug;
    const poolSlug = useParams().poolSlug;
    const {data: hypervisorDetail, isLoading, error} = useGetHost(hypervisorId)
    const {data: hypervisorStorage, isLoading: isStorageLoading, error: isStorageError} = useGetStorageInfo(hypervisorId)
    const {trigger, isMutating} = useDestroyStorage(hypervisorId, poolSlug)
    const [errorObject, setErrorObject] = useState(undefined)
    const navigate = useNavigate()

    const handleDestroyStorageRequest = (values: DestroyStorageRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                destroyStorageFormik.setErrors(error.errors || {})
                setErrorObject(error || {})
            },
            onSuccess() {
                navigate(`/hypervisors/${hypervisorDetail.slug}?tab=STORAGEPOOLS`);
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    // Formik Instance for Storage Destruction
    const destroyStorageFormik = useFormik({
        initialValues: {
            configKey: ''
        },
        validationSchema: Yup.object({
            configKey: Yup.string().required("You must enter a confirmation key").oneOf([hypervisorStorage?.configKey], "Confirmation key is incorrect"),
        }),
        onSubmit: (values) => {
            handleDestroyStorageRequest(values)
        },
    });
    
    document.title = t("hypervisor.destroyStoragePool.pageTitle") + " | Gallium";
    const crumbs = [
        {
            name: t("mainNav.hypervisors"),
            link: "/hypervisors"
        },
        {
            name: hypervisorDetail?.name,
            link: `/hypervisors/${hypervisorDetail?.slug}`
        },
        {
            name: t("hypervisor.destroyStoragePool.pageTitle"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading || isStorageLoading ? (<Loader />) : error || isStorageError ? (<ErrorNotice />) : (
                        <React.Fragment>
                            <GalliumBreadcrumb title={t("hypervisor.destroyStoragePool.pageTitle")} crumbs={crumbs} />
                            <Row>
                                <Col>
                                    <FormikProvider value={destroyStorageFormik}>
                                        <Card>
                                            <ErrorAlert errorObj={errorObject} />
                                            <CardBody className="checkout-tab">
                                                <div className="mb-4">
                                                    <h5 className="mb-1">{t("hypervisor.destroyStoragePool.confirmActionTitle")}</h5>
                                                    <p className="mb-4">
                                                        {t("hypervisor.destroyStoragePool.confirmActionText")}
                                                        {" "}
                                                        <code>{hypervisorStorage?.configKey}</code>
                                                    </p>
                                                    <h6>{t("hypervisor.destroyStoragePool.impactedDisksMessage")}{" "}{hypervisorDetail?.name}</h6>
                                                    <ol>
                                                        {hypervisorDetail?.storagePools[0]?.groupDetail[0]?.devices.map((item, key) => (
                                                            <li key={key}>
                                                                {item.id}
                                                            </li>
                                                        ))}
                                                    </ol>

                                                </div>

                                                <Field
                                                    label="Enter the Key to Continue"
                                                    name="configKey"
                                                    id="configKey"
                                                    className="form-control mb-3"
                                                    placeholder=""
                                                    type="text"
                                                    component={GalliumInput}
                                                />
    

                                                <div className="text-end">
                                                    <GalliumSubmitButton spinner={isMutating} formik={destroyStorageFormik} color="danger">
                                                        {t("hypervisor.destroyStoragePool.submit")}
                                                    </GalliumSubmitButton>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </FormikProvider>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};
export default HypervisorDestroyStorage